import Axios from 'axios'
export type ApiRouteType = 'users' | 'pool'

const axios = Axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT })

export class ApiService {
  async getFile(id: any) {
    const res = await axios.get(`upload/files/${id}`)
    return res.data
  }
}

export const apiService = new ApiService()
